<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="font-weight-bold mb-4">Google Configuration</h4>
            <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="adminForm"
            >
              <form
                  class="emails-form"
                  @submit.prevent="handleSubmit(updateAPIKey)"
              >
                <label>API Key</label>
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                        v-if="isDisabled"
                        v-model="formattedPassword"
                        disabled
                        class="float-left pr-2"
                        style="width:calc(100% - 40px)"
                    />
                    <TextInput
                        v-else
                        v-model="user.settings.google_api_key"
                        class="float-left pr-2"
                        style="width:calc(100% - 40px)"
                    />
                    <button type="button" class="btn btn-link p-0" @click="isDisabled = !isDisabled">
                      <Feather v-if="isDisabled" type="edit" class="icon-lg" />
                      <Feather v-else type="x" class="icon-lg" />
                    </button>
                  </div>

                </div>
                <b-form-group class="mt-3">
                  <b-button
                      variant="primary"
                      class="btn-dark-blue"
                      :disabled="isBusy || (invalid && touched)"
                      type="submit"
                  >
                    <b-spinner v-if="isBusy" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
            <h4 class="font-weight-bold my-4">Agency Creation Webhook</h4>
            <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="adminForm"
            >
              <form
                  class="emails-form"
                  @submit.prevent="handleSubmit(updateWebhookUrl)"
              >
                <label>Webhook URL (POST with first_name, last_name, email, phone)</label>
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                        v-model="user.settings.misc.admin_webhook_url"
                        class="float-left pr-2"
                        style="width:calc(100% - 40px)"
                    />
                  </div>

                </div>
                <b-form-group class="mt-3">
                  <b-button
                      variant="primary"
                      class="btn-dark-blue"
                      :disabled="isBusy || (invalid && touched)"
                      type="submit"
                  >
                    <b-spinner v-if="isBusy" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      isBusy:false,
      isDisabled:true,
      user:{
        settings:{}
      }
    }
  },

  computed: {
    formattedPassword:{
      get(){
        return '*'.repeat(this.user.settings.google_api_key?.length - 7) + this.user.settings.google_api_key?.slice(-7);
      },
      set(value){
        this.user.settings.google_api_key = value;
      }
    }
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
  },

  methods: {
    updateAPIKey(){
      this.isBusy = true

      let params = {
        google_api_key:this.user.settings.google_api_key,
        admin_setting_id:this.user.settings.id,
        setting_form:'admin_settings'
      }

      this.$store
          .dispatch('app/editAdminSettings', params)
          .then(() => {
            this.isBusy = false
            this.isDisabled = true
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.adminForm.setErrors(err.response.data.errors)
            }
            this.isBusy = false
          })
    },
    updateWebhookUrl(){
      this.isBusy = true

      let params = {
        admin_webhook_url:this.user.settings.misc.admin_webhook_url,
        admin_setting_id:this.user.settings.id,
        setting_form:'admin_settings'
      }

      this.$store
          .dispatch('app/editAdminSettings', params)
          .then(() => {
            this.isBusy = false
            this.isDisabled = true
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.adminForm.setErrors(err.response.data.errors)
            }
            this.isBusy = false
          })
    },
  },
}
</script>
